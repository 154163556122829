<template>
    <BlockContainer
        :background-color="data.backgroundColor"
        :bottom-angle="data.bottomAngle"
        :angle-background-color="data.nextBackgroundColor"
    >
        <div class="gap-x-8 py-8 md:py-20 lg:flex">
            <div class="relative mb-12 md:mb-0 lg:w-1/4">
                <div class="lg:top-32">
                    <div class="flex flex-col">
                        <div class="mb-4 flex justify-between">
                            <div>
                                FILTER BY ({{ filteredInspirationItems.length }}
                                RESULTS)
                            </div>
                            <a class="cursor-pointer" @click="resetFilters">
                                Reset
                            </a>
                        </div>
                        <ProductFilterRefinementListContent
                            v-if="data.productCategoryFilters?.length > 0"
                            title="Product Category"
                            type="Inspiration Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="productCategoryFilterItems"
                            @toggle="toggleProductCategoryFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.industryFilters?.length > 0"
                            title="Industry"
                            type="Inspiration Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="industryFilterItems"
                            @toggle="toggleIndustryFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.materialFilters?.length > 0"
                            title="Material"
                            type="Inspiration Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="materialFilterItems"
                            @toggle="toggleMaterialFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.designStyleFilters?.length > 0"
                            title="Design Style"
                            type="Inspiration Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="designStyleFilterItems"
                            @toggle="toggleDesignStyleFilter"
                        />
                        <ProductFilterRefinementListContent
                            v-if="data.propertyAndEffectFilters?.length > 0"
                            title="Property and Effect"
                            type="Inspiration Gallery"
                            :expanded-default="false"
                            :mobile-menu-open="false"
                            :items="propertyAndEffectFilterItems"
                            @toggle="togglePropertyAndEffectFilter"
                        />
                    </div>
                </div>
            </div>
            <div class="lg:w-3/4">
                <div
                    class="grid grid-cols-1 gap-5 md:grid-cols-2 md:px-0 lg:grid-cols-2"
                >
                    <div
                        v-for="item in filteredInspirationItems"
                        :key="'item-' + item.title"
                        class="flex h-[400px] flex-col justify-start text-center sm:h-[475px] 2xl:h-[500px]"
                    >
                        <StoryblokImage
                            v-if="item.image"
                            :src="item.image.src"
                            :alt="item.image.alt"
                            class="relative rounded-lg border border-gray-100 bg-white"
                        />
                        <h3 class="pt-5">{{ item.title }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </BlockContainer>
</template>

<script setup lang="ts">
import InspirationGalleryType from '~/types/InspirationGalleryType';
import InspirationItemType from '~/types/InspirationItemType';
import BlockContainer from '~/components/BlockContainer.vue';
import ProductFilterRefinementListContent from '~/components/page-building/product-filter/components/ProductFilterRefinementListContent.vue';

const props = defineProps<{
    data: InspirationGalleryType;
}>();

const productCategoryFilters = ref<any>([]);
const industryFilters = ref<any>([]);
const materialFilters = ref<any>([]);
const designStyleFilters = ref<any>([]);
const propertyAndEffectFilters = ref<any>([]);

const productCategoryFilterItems = ref<any>([]);
const industryFilterItems = ref<any>([]);
const materialFilterItems = ref<any>([]);
const designStyleFilterItems = ref<any>([]);
const propertyAndEffectFilterItems = ref<any>([]);

const filteredInspirationItems = ref<InspirationItemType[]>(
    props.data.inspirationItems,
);
const selectedFilterCount = ref<any>(0);

onMounted(() => {
    resetFilters();
});

const resetFilters = () => {
    productCategoryFilterItems.value = [];
    props.data.productCategoryFilters.forEach((filter: string) => {
        productCategoryFilters.value[filter] = false;
        productCategoryFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    industryFilterItems.value = [];
    props.data.industryFilters.forEach((filter: string) => {
        industryFilters.value[filter] = false;
        industryFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    materialFilterItems.value = [];
    props.data.materialFilters.forEach((filter: string) => {
        materialFilters.value[filter] = false;
        materialFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    designStyleFilterItems.value = [];
    props.data.designStyleFilters.forEach((filter: string) => {
        designStyleFilters.value[filter] = false;
        designStyleFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    propertyAndEffectFilterItems.value = [];
    props.data.propertyAndEffectFilters.forEach((filter: string) => {
        propertyAndEffectFilters.value[filter] = false;
        propertyAndEffectFilterItems.value.push({
            value: filter,
            isRefined: false,
        });
    });
    selectedFilterCount.value = 0;

    nextTick(() => {
        filterInspirationItems();
    });
};

const filterInspirationItems = () => {
    filteredInspirationItems.value = [];
    props.data.inspirationItems.forEach((item: InspirationItemType) => {
        const filteredOut =
            processFilter(
                [item.productCategory],
                props.data.productCategoryFilters,
                productCategoryFilters.value,
            ) ||
            processFilter(
                [item.industry],
                props.data.industryFilters,
                industryFilters.value,
            ) ||
            processFilter(
                [item.material],
                props.data.materialFilters,
                materialFilters.value,
            ) ||
            processFilter(
                [item.designStyle],
                props.data.designStyleFilters,
                designStyleFilters.value,
            ) ||
            processFilter(
                [item.propertyAndEffect],
                props.data.propertyAndEffectFilters,
                propertyAndEffectFilters.value,
            );

        if (!filteredOut) {
            filteredInspirationItems.value.push(item);
        }
    });
};

const processFilter = (
    itemFilterValue: string[],
    filters: string[],
    filterValues: any,
) => {
    if (!includesActiveFilter(filters, filterValues)) {
        return false;
    }

    let filterHit = false;
    filters.forEach((filter: string) => {
        if (itemFilterValue.includes(filter) && filterValues[filter]) {
            filterHit = true;
        }
    });

    return !filterHit;
};

const includesActiveFilter = (filterOptions: string[], filterValues: any) => {
    let active = false;
    filterOptions.forEach((filter: string) => {
        if (filterValues[filter]) {
            active = true;
        }
    });
    return active;
};

const toggleProductCategoryFilter = (evt: any, filter: string) => {
    productCategoryFilters.value[filter] = !productCategoryFilters.value[filter];
    selectedFilterCount.value += productCategoryFilters.value[filter] ? 1 : -1;
    toggleFilterItem(productCategoryFilterItems, filter);
    nextTick(() => {
        filterInspirationItems();
    });
};

const toggleIndustryFilter = (evt: any, filter: string) => {
    industryFilters.value[filter] = !industryFilters.value[filter];
    selectedFilterCount.value += industryFilters.value[filter] ? 1 : -1;
    toggleFilterItem(industryFilterItems, filter);
    nextTick(() => {
        filterInspirationItems();
    });
};

const toggleMaterialFilter = (evt: any, filter: string) => {
    materialFilters.value[filter] = !materialFilters.value[filter];
    selectedFilterCount.value += materialFilters.value[filter] ? 1 : -1;
    toggleFilterItem(materialFilterItems, filter);
    nextTick(() => {
        filterInspirationItems();
    });
};

const toggleDesignStyleFilter = (evt: any, filter: string) => {
    designStyleFilters.value[filter] = !designStyleFilters.value[filter];
    selectedFilterCount.value += designStyleFilters.value[filter] ? 1 : -1;
    toggleFilterItem(designStyleFilterItems, filter);
    nextTick(() => {
        filterInspirationItems();
    });
};

const togglePropertyAndEffectFilter = (evt: any, filter: string) => {
    propertyAndEffectFilters.value[filter] = !propertyAndEffectFilters.value[filter];
    selectedFilterCount.value += propertyAndEffectFilters.value[filter] ? 1 : -1;
    toggleFilterItem(propertyAndEffectFilterItems, filter);
    nextTick(() => {
        filterInspirationItems();
    });
};

const toggleFilterItem = (items: any, filter: string) => {
    for (let i = 0; i < items.value.length; i++) {
        if (items.value[i].value == filter) {
            items.value[i].isRefined = !items.value[i].isRefined;
        }
    }
};
</script>
